<template>
  <div class="wrapper" id="marketManage">
    <ctheader></ctheader>
    <div class="content" ref="marketManage" style="min-width: 1300px">
      <div class="table-head" style="margin-bottom:50px">
        <div class="left">
          <input
            type="text"
            class="w200 search"
            placeholder="所属客户名称"
            @keypress.enter="list()"
            v-model="search.company_name"
          />
          <i class="sdnm-icon sdnm-search" @click="list()"></i>
          <input
            type="text"
            class="w200 search"
            placeholder="市场名称"
            @keypress.enter="list()"
            v-model="search.market_name"
          />
          <i class="sdnm-icon sdnm-search" @click="list()"></i>
          <input
            type="text"
            class="w200 search"
            placeholder="联系人名称"
            @keypress.enter="list()"
            v-model="search.contact"
          />
          <i class="sdnm-icon sdnm-search" @click="list()"></i>
          <input
            type="text"
            class="w200 search"
            placeholder="联系电话"
            @keypress.enter="list()"
            v-model="search.tel"
          />
          <i class="sdnm-icon sdnm-search" @click="list()"></i>
          <input
            type="text"
            class="w200 search"
            placeholder="渠道商"
            @keypress.enter="list()"
            v-model="search.channel_name"
          />
          <i class="sdnm-icon sdnm-search" @click="list()"></i>
          <select
            class="w200"
            v-model="search.expiration_status"
            @change="list()"
          >
            <option :value="row.id" v-for="row in typeList">{{
              row.name
            }}</option>
          </select>
          <select class="w200" v-model="search.is_official" @change="list()">
            <option value="">全部类型</option>
            <option :value="row.id" v-for="row in official">{{
              row.name
            }}</option>
          </select>
        </div>
        <div class="right">
          <button class="btn primary" @click="edit()">新建市场</button>
        </div>
      </div>
      <div class="table-head">
        <div class="left">
          <div class="gray mgr18">
            <label for="">总市场数：</label><span>{{ total.all_num }}</span>
          </div>
          <div class="yellow mgr18">
            <label for="">即将到期：</label
            ><span>{{ total.about_out_num }}</span>
          </div>
          <div class="red mgr18">
            <label for="">已过期：</label><span>{{ total.out_num }}</span>
          </div>
        </div>
      </div>
      <div class="table-scroll">
        <table class="table" width="100%" :style="{ 'min-width': '1600px' }">
          <thead>
            <tr>
              <th class="pw7 ellipsis">市场编号</th>
              <th class="pw8 ellipsis">市场名称</th>
              <th class="pw6 ellipsis">功能权限</th>
              <th class="pw5 ellipsis">渠道商</th>
              <th class="pw11 ellipsis">所在行政区划</th>
              <th class="pw7 ellipsis">详细地址</th>
              <th class="pw6 ellipsis">所属客户</th>
              <th class="pw7 ellipsis">所属OEM版本</th>
              <th class="pw7 ellipsis">主要负责人</th>
              <th class="pw7 ellipsis">联系电话</th>
              <th class="pw5 ellipsis">状态</th>
              <th class="pw10 ellipsis">有效期</th>
              <th class="pw14">操作</th>
            </tr>
          </thead>
          <tbody :style="{ height: mmtbHeight + 'px' }">
            <template v-for="row in data">
              <tr>
                <td class="pw7 ellipsis" :title="row.market_code">
                  {{ row.market_code }}
                </td>
                <td class="pw8 ellipsis" :title="row.market_name">
                  {{ row.market_name }}
                </td>
                <td class="pw6 ellipsis" :title="row.area">
                  <span v-for="item in row.version" :key="item.id">
                    {{ item.version_name }},
                  </span>
                </td>
                <td class="pw5 ellipsis" :title="row.market_name">
                  {{ row.channel_name }}
                </td>
                <td class="pw11 ellipsis" :title="row.area">{{ row.area }}</td>
                <td class="pw7 ellipsis" :title="row.addr_detail">
                  {{ row.addr_detail }}
                </td>
                <td class="pw6 ellipsis" :title="row.company_name">
                  {{ row.company_name }}
                </td>
                <td class="pw6 ellipsis" :title="row.oem_name">
                  {{ row.oem_name }}
                </td>
                <td class="pw8 ellipsis" :title="row.responsible_person">
                  {{ row.responsible_person }}
                </td>
                <td class="pw7 ellipsis" :title="row.responsible_mobile">
                  {{ row.responsible_mobile }}
                </td>
                <td class="pw5 ellipsis" :title="row.expiration_status_name">
                  <span :class="row.className">{{
                    row.expiration_status_name
                  }}</span>
                </td>
                <td class="pw10 ellipsis" :title="row.expiration_date">
                  <span :class="row.className">{{ row.expiration_date }}</span>
                </td>
                <td class="operation pw14">
                  <a @click="showDevicesLayer(row)">设备设置</a>
                  <a @click="edit(row)">编辑</a>
                  <a @click="detail(row.id)">查看详情</a>
                </td>
              </tr>
            </template>
            <tr v-if="data.length === 0">
              <td class="empty" colspan="8">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :start="search.start"
        :total="config.total"
        :page-length="search.length"
        :pageCallback="list"
      ></pagination>
    </div>
    <side
      class="devices-side"
      :show="devicesSideShow"
      :width="400"
      title="设备数量设置"
      :btnLoadDisabled="devicesSideDisabled"
      :closeCallBack="devicesSideCancel"
      :saveCallBack="devicesSideSave"
    >
      市场名称：{{ deviceObjName }}
      <div class="form">
        <div class="field">
          <label class="w100 va-m tart">POS：</label>
          <input
            type="number"
            @input="inputNum($event, 'pos')"
            maxlength="20"
            class="w200 mgt-16"
            v-model="deviceNum.pos"
          />
        </div>
        <div class="field">
          <label class="w100 va-m tart">档口公示屏：</label>
          <input
            type="number"
            @input="inputNum($event, 'orgz_public_screen')"
            maxlength="20"
            class="w200 mgt-16"
            v-model="deviceNum.orgz_public_screen"
          />
        </div>
        <div class="field">
          <label class="w100 va-m tart">市场导视屏：</label>
          <input
            type="number"
            @input="inputNum($event, 'market_public_screen')"
            maxlength="20"
            class="w200 mgt-16"
            v-model="deviceNum.market_public_screen"
          />
        </div>
        <div class="field">
          <label class="w100 va-m tart">市场公示屏：</label>
          <input
            type="number"
            @input="inputNum($event, 'market_display_screen')"
            maxlength="20"
            class="w200 mgt-16"
            v-model="deviceNum.market_display_screen"
          />
        </div>
        <div class="field">
          <label class="w100 va-m tart">市场质检屏：</label>
          <input
            type="number"
            @input="inputNum($event, 'market_quality_screen')"
            maxlength="20"
            class="w200 mgt-16"
            v-model="deviceNum.market_quality_screen"
          />
        </div>
      </div>
    </side>
    <side
      class="market-side"
      :show="sideShow"
      :width="540"
      :title="sideTitle"
      :btnLoadDisabled="sideDisabled"
      :closeCallBack="sideCancel"
      :saveCallBack="sideSave"
    >
      <div class="form">
        <div class="field img-center">
          <img-upload
            :upload-action="uploadAction"
            :extra-data="uploadData"
            :url="uploadUrl"
            :isDelete="true"
            imgTitle=""
            title="上传市场Logo"
            name="file1"
            :returnObj="true"
            @delete-callback="deletePic"
            @upload-preview="uploadPreview"
            @upload-callback="uploadImg"
          >
          </img-upload>
          <div class="upload-tip" v-if="!uploadUrl">
            上传图片格式必须为png、jpeg、jpg，不大于3M
          </div>
        </div>

        <div class="field">
          <label class="w100 tart">所属客户：</label>
          <select class="w300" v-model="side.hq_code" @change="setHqCode">
            <option
              v-for="row in hqList"
              :value="row.code"
              :selected="side.hq_code === row.code ? 'selected' : ''"
              >{{ row.name }}</option
            >
          </select>
        </div>
        <div class="field">
          <label class="w100 tart">所属OEM版本：</label>
          <select class="w300" v-model="side.oem_id" @change="setOem">
            <option
              v-for="row in oemList"
              :value="row.id"
              :selected="side.oem_id === row.id ? 'selected' : ''"
              >{{ row.name }}</option
            >
          </select>
        </div>
        <div class="field">
          <label class="w100 tart">市场名称：</label>
          <input
            type="text"
            maxlength="20"
            class="w300"
            v-model="side.orgz_name"
          />
        </div>
        <div class="field">
          <label class="w100 tart">市场简称：</label>
          <input type="text" maxlength="20" class="w300" v-model="side.aka" />
        </div>
        <div class="field">
          <label class="w100 tart">账户状态</label>
          <radio
            :radioData="official"
            radioName="official"
            :radioCheckedId="side.is_official"
            @change="officialChange"
          ></radio>
        </div>
        <div class="field">
          <label class="w100 tart">系统有效期</label>
          <el-date-picker
            v-model="side.expiration_date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="field">
          <label class="w100 tart">版本类型</label>
          <el-select
            v-model="type"
            placeholder="请选择"
            multiple
            @change="typeChange"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="field">
          <el-table :data="tableData" style="width: 100%" border>
            <el-table-column prop="name" label="权限名称" width="280">
            </el-table-column>
            <el-table-column label="到期时间" width="280">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.end_time"
                  type="datetime"
                  :picker-options="pickerOption"
                  value-format="timestamp"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="field">
          <label class="w100 tart">渠道商：</label>
          <select class="w300" v-model="side.channel_id">
            <option v-for="row in channelList" :value="row.id" :key="row.id">{{
              row.name
            }}</option>
          </select>
        </div>
        <div class="field">
          <label class="w100 tart">收银模式：</label>
          <radio
            :radioData="config.model"
            radioName="cash_register_type"
            :radioCheckedId="side.cash_register_type"
          ></radio>
        </div>
        <div class="field">
          <label class="w100 tart">支付方式：</label>
          <multipletree
            class="topTree"
            :dataInfo="payList"
            :height="60"
            :selHeight="64"
            :width="300"
            :singleChoose="false"
            :canChoose="false"
            name="fixedTree"
            inputPlaceholder="全部支付方式"
            allChooseName="全部支付方式"
            :cantChooseFather="false"
            :choosedArray="choosePay"
            :returnObj="true"
            :showAllChoose="false"
            @sdnm-multiple-tree="multipleTreeSet"
          >
          </multipletree>
        </div>
        <div class="field">
          <label class="w100 tart">负责人：</label>
          <input
            type="text"
            maxlength="20"
            class="w300"
            v-model="side.responsible_person"
          />
        </div>
        <div class="field">
          <label class="w100 tart">联系电话：</label>
          <input
            type="text"
            maxlength="11"
            class="w300"
            v-model="side.responsible_mobile"
            @input="checkNum(side.responsible_mobile)"
          />
        </div>
        <div class="field">
          <label class="w100 tart">所属地区：</label>
          <area-select
            :initData="areaData"
            :level="3"
            @setArea="getCity"
            position="top"
          ></area-select>
        </div>
        <div class="field">
          <label class="w100 tart">详细地址：</label>
          <textarea
            class="w300"
            v-model="side.addr_detail"
            maxlength="100"
            @blur="setAddr()"
            @keypress.enter.stop.prevent.self="setAddr()"
          ></textarea>
        </div>
        <div id="allmap"></div>
      </div>
    </side>
    <showImage
      :url="perviewUrl"
      :showBigImage="perviewShow"
      :closeCallBack="perviewCancel"
    >
    </showImage>
  </div>
</template>

<script>
/**
 * @module app/marketManage/marketManage
 * @desc 传蔬农贸版后台 市场
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-09
 * @copyright 2021
 */
/* global BMap */
import { mapGetters, mapActions } from "vuex";
import { tableResize, apiPost, parse } from "../../modules/utils";
import { event } from "../../modules/EVENTs";
import ctheader from "../../components/ctheader";
import pagination from "../../components/pagination";
import side from "../../components/side";
import imgUpload from "../../components/imgUpload";
import showImage from "../../components/showImage";
import areaSelect from "../../components/areaSelect";
import radio from "../../components/radio";
import multipletree from "../../components/multipletree";
import flatpickr from "../../components/flatpickr";
// import ctheader from '@@/ctheader';
// import pagination from '@@/pagination';
// import side from '@@/side';
// import areaSelect from '@@/areaSelect';
// import imgUpload from '@@/imgUpload';
// import showImage from '@@/showImage';
// import radio from '@@/radio';
// import APIs from '_/APIs';
// import router from '@/router';
// import multipletree from '@@/multipletree';
// import flatpickr from '@@/flatpickr';
import router from "../../router";
import APIs from "../../modules/APIs";
import * as URL from "../../modules/URLs";
const getters = mapGetters(["userInfo"]);
const actions = mapActions(["setAlert"]);

let map = null;
let marker = null; // 地图标点

export default {
  name: "marketmanage",
  computed: {
    ...getters,
  },
  components: {
    ctheader,
    pagination,
    side,
    areaSelect,
    imgUpload,
    showImage,
    radio,
    multipletree,
    flatpickr,
  },

  data() {
    return {
      area: {
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        areaId: "",
        areaName: "",
      },
      search: {
        start: 0,
        length: 10,
        keyword: "", // 关键词
        company_name: "", // 公司名搜索字段
        market_name: "", // 市场名搜索字段
        contact: "", // 联系人搜索字段
        tel: "", // 电话搜索字段
        district_id: "", // 地区
        expiration_status: 0,
        is_official: "",
        channel_name: "",
      }, // 固定柜组搜索内容
      mmtbHeight: "", // 表格高度
      data: [], // 表格数据
      type: "",
      options: [],
      tableData: [],
      config: {
        total: 0,
        fields: [
          { key: "cash_name", value: "收银模式" },
          { key: "area", value: "所属地区" },
          { key: "addr_detail", value: "详细地址" },
          { key: "responsible_person", value: "负责人" },
          { key: "responsible_mobile", value: "联系电话" },
        ],
        model: [
          {
            id: 0,
            name: "分散收银",
          },
          {
            id: 1,
            name: "集中收银",
          },
        ],
        model_hash: {
          0: "分散收银",
          1: "集中收银",
        },
        point: {},
      },

      // 弹框
      hqList: [],
      sideShow: false,
      sideDisabled: false,
      sideTitle: "新建市场",
      side: {
        hq_code: "",
        img_url: "",
        orgz_name: "",
        responsible_person: "",
        responsible_mobile: "",
        district_id: "",
        addr_detail: "",
        cash_register_type: 0,
        oem_id: "",
        is_official: 1,
        expiration_date: "",
        channel_id: "",
        mode_ids: "",
        aka: "",
      },
      pickerOption: this.endTime(),
      areaData: {
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        areaId: "",
        areaName: "",
      },
      perviewUrl: "", // perview
      perviewShow: false,
      // initIMG: {
      //   upload: "/images/load/altpic.png",
      //   show: "/images/load/imgload.png",
      // }, // 上传默认图片
      initIMG: {
        upload: "",
        show: "",
      },
      uploadAction: APIs.MENU,
      uploadData: {
        action: "company.logo.upload",
      },
      // uploadUrl: "/images/load/altpic.png",
      uploadUrl: "",
      showImg: "", // hover查看商品图片
      showImgLeft: 75,
      showImgTop: 0,
      srciptId: "map-script",
      loaded: false, // 地图api加载状态

      // POS数量设置
      devicesSideShow: false,
      devicesSideDisabled: false,
      deviceNum: {
        pos: 0,
        orgz_public_screen: 0,
        market_public_screen: 0,
        market_display_screen: 0,
        market_quality_screen: 0,
      },
      deviceObjName: "",
      devideHqCode: "",
      devideId: "",
      oemList: [],
      payList: [],
      choosePay: [],
      official: [
        {
          id: 1,
          name: "已购买",
        },
        {
          id: 0,
          name: "试用",
        },
        {
          id: 2,
          name: "测试",
        },
      ], // 账户状态
      dateConfig: {
        minDate: null,
        maxDate: null,
      },
      typeList: [
        { id: 0, name: "全部有效期" },
        { id: 1, name: "未到期" },
        { id: 2, name: "即将到期" },
        { id: 3, name: "已过期" },
      ],
      channelList: [],
      total: {},
    };
  },
  created() {
    event.$on("sdnm-radio", this.sdnmRadio);
    this.getPayList();
    this.getChannelList();
    this.list();
  },
  mounted() {
    console.log(Date.now());
    this.getHqList();
    this.getOemList();
    this.getList();
    this.loadMap().then(() => {
      // 加载完毕
      this.loaded = true;
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tResize, false);
    event.$off("sdnm-radio", this.sdnmRadio);
  },
  methods: {
    ...actions,
    getChannelList() {
      let _this = this;
      apiPost({
        url: APIs.CHANNEL,
        data: {
          action: "channel.list",
          start: 0,
          length: 999999,
        },
        success(res) {
          if (res.code === "0") {
            _this.channelList = res.data;
          }
        },
        error(res) {},
      });
    },
    getDate(dates, name) {
      this.side.expiration_date = dates.start;
    },
    endTime() {
      const that = this;
      return {
        disabledDate(time) {
          const date = new Date(that.side.expiration_date);
          const mm = date.getTime();
          return time > mm;
        },
      };
    },
    multipleTreeSet(data, name) {
      this.side.mode_ids = data.map((el) => el.id).join(",");
      // this.choosePay = data;
      console.log(data);
    },

    //获取版本列表
    getList() {
      let _this = this;
      apiPost({
        url: APIs.VERSION,
        data: {
          action: "list.version",
          start: 0,
          length: 9999,
          type: 2,
          status: 1,
        },
        success(res) {
          if (res.code === "0") {
            _this.options = res.data;
          }
        },
        error(res) {},
      });
    },
    typeChange(res) {
      this.tableData = this.options.filter((c) => res.includes(c.id));
    },

    getPayList() {
      let $this = this;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "market.mode.list",
        },
        success(res) {
          if (res.code === "0") {
            $this.payList = parse(res.data).map((el) => {
              if (el.id === 1) {
                return {
                  id: el.id,
                  name: el.name,
                  children: [],
                  showAll: true,
                  closeDisabled: true,
                };
              } else {
                return {
                  id: el.id,
                  name: el.name,
                  children: [],
                  showAll: true,
                };
              }
            });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    /**
     * 表格高度重置
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-09
     */
    tResize() {
      this.mmtbHeight = tableResize(
        this.$refs.marketManage.clientHeight,
        250,
        this.data.length
      );
    },
    /**
     * 获取公司列表
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-11-08
     * @version  0.1.0
     */
    getHqList() {
      let $this = this;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "company.list",
          start: 0,
          length: 9999999,
          keyword: "",
          is_official: "",
        },
        success(res) {
          if (res.code === "0") {
            $this.hqList = res.data;
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    /**
     * 获取oem版本列表
     * @Author   qijiajun   <songkexin@rongyi.com>
     * @DateTime 2021-07-29
     * @version  0.1.0
     */
    getOemList() {
      let $this = this;
      apiPost({
        url: APIs.OEM_VERSION,
        data: {
          action: "lists",
          start: 0,
          length: 9999999,
        },
        success(res) {
          if (res.code === "0") {
            $this.oemList = res.data;
          } else {
            $this.oemList = [];
          }
        },
        error(res) {
          $this.oemList = [];
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    /**
     * 选中的客户
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-11-08
     * @version  0.1.0
     */
    setHqCode(e) {
      this.side.hq_code = e.target.value;
    },
    /**
     * 选中的oem版本
     * @Author   qijiajun   <songkexin@rongyi.com>
     * @DateTime 2021-07-29
     * @version  0.0.1
     */
    setOem(e) {
      this.side.oem_id = e.target.value;
    },
    /**
     * 列表获取数据
     * @author songkexin <songkexin@rongyi.com>
     * @date 2021-06-22
     * @param {Number} index 分页的start
     */
    list(index) {
      if (index || index === 0) {
        this.search.start = index;
      } else {
        this.search.start = 0;
      }
      let $this = this;
      apiPost({
        url: APIs.COMPANY,
        data: Object.assign({ action: "market.list" }, $this.search),
        success(res) {
          if (res.code === "0") {
            $this.data = res.data.map((el) => {
              el.expiration_status_name = "";

              if (el.expiration_status === 1) {
                el.expiration_status_name = "未过期";
                el.className = "gray";
              } else if (el.expiration_status === 2) {
                el.expiration_status_name = "即将过期";
                el.className = "yellow";
              } else if (el.expiration_status === 3) {
                el.expiration_status_name = "已过期";
                el.className = "red";
              }

              return el;
            });
            $this.config.total = res.total.total;
            $this.total = res.total;
            $this.tResize();
          } else {
            $this.data = [];
            $this.config.total = 0;
            $this.total = {};
          }
        },
        error(res) {
          $this.data = [];
          $this.config.total = 0;
          $this.total = {};
        },
      });
    },
    /**
     * 查看市场详情
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-09
     */
    detail(id) {
      // router.push(URL.MARKET_DETAIL + "/" + id);
      router.push(URL.MARKET_DETAIL + "?id=" + id);
    },
    /**
     * 获取收银模式
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-08-15
     * @param  {[type]}   index [description]
     */
    sdnmRadio(index, name) {
      if (name === "cash_register_type") {
        this.side.cash_register_type = this.config.model[index].id;
      } else if (name === "official") {
        this.side.is_official = this.official[index].id;
      }
    },
    /**
     * 上传图片的preview
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-07-13
     */
    perviewCancel() {
      this.perviewShow = false;
    },
    deletePic() {
      this.perviewUrl = "";
      this.perviewShow = false;
      this.uploadUrl = this.initIMG.upload;
      this.side.img_url = this.initIMG.upload;
    },
    uploadPreview(url) {
      this.perviewUrl = url;
      this.perviewShow = true;
    },
    uploadImg(path, type, name) {
      let url = "http://" + path.domain + "/" + path.path;
      this.uploadUrl = type === 1 ? url : this.initIMG.upload;
      this.side.img_url = this.uploadUrl;
    },
    /**
     * 鼠标移入图片显示大图
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-29
     * @param  {Object}   event 当前的图片对象
     * @param  {String}   img   图片地址
     */
    imgEnter(event, img) {
      let imgRect = event.target.getBoundingClientRect();
      let clHeight = document.body.clientHeight;
      if (clHeight - imgRect.bottom < 180) {
        this.showImgTop = imgRect.bottom - 280;
      } else {
        this.showImgTop = imgRect.y - 50;
      }
      this.showImgLeft = 90;
      this.showImg = img;
    },
    /**
     * 鼠标移出图片关闭大图
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-29
     */
    imgLeave() {
      this.showImg = "";
    },
    /**
     * 获取行政下拉区id
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-06-20
     */
    getCity(val) {
      if (val.provinceName === "其他" || val.provinceName === "海外") {
        this.side.district_id = val.provinceId;
      } else {
        this.side.district_id = val.areaId;
      }
      this.areaData = val;
      this.setAddr();
    },
    /**
     * 验证输入的电话号码
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-06-20
     */
    checkNum(val) {
      let reg = /(?:1[0-9]*)/;
      let result = reg.exec(val);
      this.side.responsible_mobile = result !== null ? result[0] : "";
    },
    /**
     * 打开编辑弹框 || 新增
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-06-20
     */
    officialChange(res) {
      this.$forceUpdate();
    },
    edit(row) {
      this.$forceUpdate();
      this.config.point = {};
      this.payList.forEach((el) => {
        el.showAll = true;
      });
      if (row) {
        this.sideTitle = "编辑市场";
        this.uploadUrl = row.img_url !== "" ? row.img_url : this.initIMG.upload;
        this.side.img_url = row.img_url;
        this.side.orgz_name = row.orgz_name; // no
        this.side.responsible_person = row.responsible_person; // no
        this.side.responsible_mobile = row.responsible_mobile; // no
        this.side.district_id = row.district_id;
        this.side.addr_detail = row.addr_detail;
        this.side.cash_register_type = row.cash_register_type;
        this.side.hq_code = row.hq_code;
        this.side.id = row.id;
        this.side.oem_id = row.oem_id;
        this.side.user_name = undefined;
        this.side.aka = row.aka;
        this.side.is_official = row.is_official;
        this.side.channel_id = row.channel_id;
        this.type = row.version && row.version.map((item) => item.id);
        this.tableData =
          row.version &&
          row.version.map((item) => {
            return {
              name: item.version_name,
              id: item.id,
              end_time: item.end_time * 1000,
            };
          });
        this.choosePay = row.payment_mode.map((el) => {
          if (el.mode_id === 1) {
            return {
              id: el.mode_id,
              name: el.name,
              closeDisabled: true,
            };
          } else {
            return {
              id: el.mode_id,
              name: el.name,
            };
          }
        });
        this.side.expiration_date = row.expiration_date;
        this.areaData = {
          provinceId: row.province_id,
          provinceName: row.province_name,
          cityId: row.city_id,
          cityName: row.city_name,
          areaId: row.district_id,
          areaName: row.district_name,
        };
        let type = 1; // 1没有定位, 2经纬度定位, 3地址定位
        let val = {}; // 定位信息
        if (row.lng) {
          type = 2;
          val = {
            lng: row.lng,
            lat: row.lat,
          };
        } else if (row.area) {
          type = 3;
          val = {
            area: row.area,
            addr_detail: row.addr_detail || "",
          };
        }
        this.initMap(type, val);
      } else {
        this.sideTitle = "新建市场";
        this.choosePay = parse(this.payList)
          .filter((el) => el.id === 1)
          .map((el) => {
            el.closeDisabled = true;
            return el;
          });
        this.side.mode_ids = parse(this.payList)
          .map((el) => el.id)
          .join(",");
        this.side.user_name = this.userInfo.username;
        this.uploadUrl = this.initIMG.upload;
        this.side.cash_register_type = 0;
        this.initMap(1);
      }
      this.sideShow = true;
    },
    /**
     * 关闭编辑弹框
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-06-20
     */
    sideCancel() {
      this.$forceUpdate();
      this.side = {
        img_url: this.initIMG.upload,
        orgz_name: "",
        responsible_person: "",
        responsible_mobile: "",
        district_id: "",
        addr_detail: "",
        cash_register_type: 0,
        latitude: "", // 经纬坐标
        longitude: "",
        is_official: 1,
      };
      this.areaData = {
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        areaId: "",
        areaName: "",
      };
      this.sideShow = false;
    },
    /**
     * 保存市场
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-06-20
     */
    sideSave() {
      if (this.side.orgz_name === "") {
        this.setAlert({ msg: "请输入市场名称", type: false });
        return;
      }
      if (
        this.side.responsible_mobile !== "" &&
        this.side.responsible_mobile.length !== 11
      ) {
        this.setAlert({ msg: "请输入正确的手机号码", type: false });
        return;
      }
      if (this.areaData.provinceId) {
        if (
          this.areaData.provinceName === "国外" ||
          this.areaData.provinceName === "其他"
        ) {
          this.side.district_id = this.areaData.provinceId;
        }
        if (!this.side.district_id) {
          this.setAlert({ msg: "所属地区选择到区", type: false });
          return;
        }
      } else {
        this.setAlert({ msg: "请填写市场所在地址", type: false });
        return;
      }
      let point = this.config.point;
      if (point.lat) {
        this.side.lat = point.lat;
        this.side.lng = point.lng;
      }
      this.side.action = "market.add";
      if (this.side.id) {
        this.side.action = "market.edit";
      }
      if (this.side.img_url === this.initIMG.upload) {
        this.side.img_url = "";
      }
      this.sideDisabled = true;

      let that = this;
      that.side.pay_version_json = that.tableData.map((item) => {
        return {
          id: item.id,
          end_time: item.end_time / 1000,
        };
      });
      apiPost({
        url: APIs.COMPANY,
        data: that.side,
        success(res) {
          if (res.code + "" === "0") {
            that.setAlert({ msg: res.msg, type: true });
            that.sideCancel();
            that.list();
          } else {
            that.setAlert({ msg: res.msg, type: false });
          }
          that.sideDisabled = false;
        },
        error(res) {
          that.setAlert({ msg: res, type: false });
          that.sideDisabled = false;
        },
      });
    },
    /**
     * 实时变更地址
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-08-28
     */
    setAddr() {
      let d = this.areaData; // 省市区
      let area =
        (d.provinceName || "") + (d.cityName || "") + (d.areaName || "");
      this.initMap(3, {
        area: area,
        addr_detail: this.side.addr_detail || "",
      });
    },
    /**
     * 引入map api
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-08-28
     * @return {[type]}   [description]
     */
    loadMap() {
      return new Promise((resolve, reject) => {
        if (typeof BMap !== "undefined") {
          resolve(BMap);
          return;
        }
        window.createMap = () => {
          resolve(BMap);
        };
        let script = document.createElement("script");
        script.id = this.srciptId;
        script.src =
          "https://api.map.baidu.com/api?v=2.0&ak=hZC74lInQ0kC&callback=createMap";
        script.onerroe = reject;
        document.body.appendChild(script);
      });
    },
    /**
     * 绘制地图标点并展示地图
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-08-28
     */
    getMark(point) {
      this.config.point = point;
      if (map && marker) {
        map.removeOverlay(marker);
      }
      marker = new BMap.Marker(point);
      map.centerAndZoom(point, 15);
      map.addOverlay(marker); // 在地图中添加标注
      marker.enableDragging();
      marker.addEventListener("dragend", (e) => {
        this.config.point = e.point;
      });
      map.addEventListener("click", (e) => {
        this.initMap(2, e.point);
      });
    },
    /**
     * 获取网络地址
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-08-28
     */
    getNetGeo() {
      let geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition((geo) => {
        if (geo) {
          this.initMap(2, geo.point); // 标注位置
        } else {
          this.initMap(4);
        }
      });
    },
    /**
     * 根据不同类型判断地图获取point
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-08-28
     * @param  {[type]}   type 1->获取浏览器网络位置,2->经纬度, 3->省市区详细地址,4->默认定位天安门
     * @param  {[type]}   val  [description]
     */
    initMap(type, val) {
      if (!map) {
        map = new BMap.Map("allmap");
        map.enableScrollWheelZoom(true); // 允许鼠标滚轮缩放
      }
      if (type === 1) {
        this.getNetGeo();
      } else if (type === 2) {
        let point = new BMap.Point(val.lng, val.lat); // 设定经纬坐标
        this.getMark(point);
      } else if (type === 3) {
        let geoPoint = new BMap.Geocoder(); // 通过地址获取经纬度
        let addr = val.area + val.addr_detail;
        geoPoint.getPoint(addr, (point) => {
          if (point) {
            this.getMark(point);
          } else {
            this.initMap(4);
          }
        });
      } else if (type === 4) {
        let point = new BMap.Point(116.404, 39.915); // 默认定位天安门
        this.getMark(point);
      }
    },
    showDevicesLayer(row) {
      let that = this;
      that.devicesSideDisabled = true;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "market.devices.setting.info",
          hq_code: row.hq_code,
          market_id: row.id + "",
        },
        success(res) {
          if (res.code + "" === "0") {
            that.deviceNum = {
              pos: res.data.pos || 0,
              orgz_public_screen: res.data.orgz_public_screen || 0,
              market_public_screen: res.data.market_public_screen || 0,
              market_display_screen: res.data.market_display_screen || 0,
              market_quality_screen: res.data.market_quality_screen || 0,
            };
            that.devicesSideShow = true;
            that.devideHqCode = row.hq_code;
            that.devideId = row.id;
            that.deviceObjName = row.market_name;
          } else {
            that.setAlert({ msg: res.msg, type: false });
          }
          that.devicesSideDisabled = false;
        },
        error(res) {
          that.setAlert({ msg: res, type: false });
          that.devicesSideDisabled = false;
        },
      });
    },
    devicesSideCancel() {
      this.deviceNum = {
        pos: 0,
        orgz_public_screen: 0,
        market_public_screen: 0,
        market_display_screen: 0,
        market_quality_screen: 0,
      };
      this.devicesSideShow = false;
    },
    devicesSideSave() {
      let that = this;
      that.devicesSideDisabled = true;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "market.devices.setting",
          hq_code: that.devideHqCode,
          market_id: that.devideId,
          pos: that.deviceNum.pos,
          orgz_public_screen: that.deviceNum.orgz_public_screen,
          market_public_screen: that.deviceNum.market_public_screen,
          market_display_screen: that.deviceNum.market_display_screen,
          market_quality_screen: that.deviceNum.market_quality_screen,
        },
        success(res) {
          if (res.code + "" === "0") {
            that.setAlert({ msg: res.msg, type: true });
            that.devicesSideShow = false;
          } else {
            that.setAlert({ msg: res.msg, type: false });
          }
          that.devicesSideDisabled = false;
        },
        error(res) {
          that.setAlert({ msg: res, type: false });
          that.devicesSideDisabled = false;
        },
      });
    },
    inputNum(e, type) {
      console.log(e.target.value.slice(0, 4));
      let val = e.target.value.slice(0, 4);
      if (val > 0) {
        this.deviceNum[type] = val;
      } else {
        alert("设备数量大于0");
        this.deviceNum[type] = 1;
      }
    },
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
#marketManage .lf96 {
  left: 96px;
}
#marketManage .topTree {
  .va-t();
}
#marketManage .form {
  .field {
    &.h80 {
      height: 80px;
    }
  }
  .field:first-child {
    label {
      .pdl(0);
    }
  }
  .field:nth-child(2) {
    label {
      .pdl(27px);
    }
  }
}
#marketManage {
  .side .form {
    .img-center {
      display: block;
      text-align: center;
      .upload-tip {
        margin-top: -16px;
        .fc(12px, #525e6e);
        text-align: center;
      }
    }
    .field {
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 20px;
      }
      .tart {
        text-align: right;
      }
      & > label:first-child {
        .pdl(0);
      }
    }
    .va-m {
      vertical-align: middle;
    }
  }
  #allmap {
    height: 300px;
    /*margin-top: -35px;*/
  }
  .show-img {
    z-index: 99;
    .wh(180px, 180px);
    .bgc(#ffffff);
    .brd(1px, solid, #eceff3);
    box-shadow: 0 2px 6px 0 rgba(163, 163, 163, 0.5);
    border-radius: 4px;
    .pd(10px);
    position: absolute;
    img {
      border: 1px solid #eceff3;
      border-radius: 3px;
      .wh(100%, 100%);
    }
  }
  .mgt-16 {
    .mgt(16px);
  }
  .gray {
    .dib();
    .fc(14px, #666);
  }
  .yellow {
    .dib();
    .fc(14px, rgb(255, 153, 0));
  }
  .red {
    .dib();
    .fc(14px, rgb(255, 0, 0));
  }
  .mgr18 {
    .mgr(18px);
  }
}
</style>
